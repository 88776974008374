import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const TypesOfCampaigns = ({ location }) => {
  const title = "インスタントウィン以外のキャンペーンも開催できる？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoTでは参加方法→フォロー&リツイート・ハッシュタグ・レシート投稿、抽選方法→手動抽選・ランダム抽選・スクラッチ・ゲーム、プレゼント→商品・デジタルギフト・デジタルクーポンから選びキャンペーンを開催できます。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              どんなキャンペーンが開催できますか？
            </h1>
            <p className="qa-page__answer">
              「フォロー＆リツイートキャンペーン」「ハッシュタグキャンペーン」「レシート投稿キャンペーン」が開催できます。
              <br />
              抽選方法やプレゼントはターゲットや取り扱っている商品・サービスに合わせて選択できます。
            </p>
            <div className="qa-page__detail-container">
              <p className="qa-page__text">
                PARKLoTのキャンペーンは
                <br />
                ①企業公式ツイッターアカウントがキャンペーンツイートをツイート
                <br />
                ②ユーザーがキャンペーンに参加
                <br />
                ③抽選
                <br />
                ④景品獲得
                <br />
                というフローで開催します。
              </p>
              <p className="qa-page__text">
                このフローの「②ユーザーがキャンペーンに参加」「③抽選」「④景品獲得」にはそれぞれ複数の方法があり、キャンペーンの目的や提供する商品・サービスに合わせて選択し、キャンペーンを開催できます。
              </p>
              <p className="qa-page__text">
                ここではそれぞれの選択肢の概要、料金について説明します。
              </p>
              <h2 className="qa-page__h2">
                「②ユーザーがキャンペーンに参加」で選べる選択肢について
              </h2>
              <p className="qa-page__text">
                参加方法は3種類です。
                <br />
                ①フォロー＆リツイート
                <br />
                ②ハッシュタグ
                <br />
                ③レシート投稿
                <br />
              </p>
              <h3 className="qa-page__h3">①フォロー＆リツイート</h3>
              <p className="qa-page__text">
                企業公式アカウントをフォローし、キャンペーンツイートをリツイートしキャンペーンに参加します。
              </p>
              <p className="qa-page__text">月額定額プランに含まれます。</p>
              <h3 className="qa-page__h3">②ハッシュタグ</h3>
              <p className="qa-page__text">
                特定のハッシュタグを含むツイートをツイートすることでキャンペーンに参加します。
              </p>
              <p className="qa-page__text">
                月額定額プランに加えオプション料金が必要です。
              </p>
              <h3 className="qa-page__h3">③レシート投稿</h3>
              <p className="qa-page__text">
                商品を購入したレシートをDMで送りキャンペーンに参加します。
              </p>
              <p className="qa-page__text">
                月額定額プランに加えオプション料金が必要です。
              </p>
              <h2 className="qa-page__h2">「③抽選」で選べる選択肢について</h2>
              <p className="qa-page__text">
                抽選方法は「期間終了後抽選」と「リアルタイム抽選」があります。
              </p>
              <p className="qa-page__text">
                「期間終了後抽選」は「ランダム抽選」「手動抽選」の2つ。
                <br />
                「リアルタイム抽選の場合は「ランダム抽選」「スクラッチ」「ゲーム」の3種類があります。
                <br />
              </p>
              <h3 className="qa-page__h3">①期間終了後抽選</h3>
              <p className="qa-page__text">
                キャンペーン期間終了後にまとめて抽選し、当選結果を参加者に通知します。
              </p>
              <p className="qa-page__text">
                手動抽選：応募者の一覧から手動で当選者を選択し、当選者にDMを手動で送信します。
              </p>
              <p className="qa-page__text">
                ランダム抽選：応募者の一覧からランダムで当選者を選択し、当選者にDMを手動で送信します。
              </p>
              <h3 className="qa-page__h3">②リアルタイム抽選</h3>
              <p className="qa-page__text">
                キャンペーンに参加すると、即座に参加者に当落結果が通知されます。
              </p>
              <p className="qa-page__text">
                リアルタイム抽選の場合は抽選方法を3種類から選択できます。
              </p>
              <h4 className="qa-page__h4">ランダム抽選</h4>
              <p className="qa-page__text">
                あらかじめ設定しておいた当選率に従ってPARKLoTのシステムで自動で抽選し、参加者に抽選結果の確認画面のURL含んだDMを送信します。
              </p>
              <p className="qa-page__text">
                DMを受け取った参加者が抽選結果確認画面へ遷移し、結果を確認します。
              </p>
              <p className="qa-page__text">月額定額プランに含まれます。</p>
              <h4 className="qa-page__h4">スクラッチ</h4>
              <p className="qa-page__text">
                参加者にスクラッチカードのURLを含んだDMを送信します。
              </p>
              <p className="qa-page__text">
                DMを受け取った参加者がURLをクリックするとスクラッチカードが表示され、カードを削って当選結果を確認します。
              </p>
              <p className="qa-page__text">
                月額定額プランに加えオプション料金が必要です。
              </p>
              <h4 className="qa-page__h4">ゲーム</h4>
              <p className="qa-page__text">
                参加者にゲームへの参加URLを含んだDMを送信します。
              </p>
              <p className="qa-page__text">
                DMを受け取った参加者がURLをクリックしゲームに参加します。ゲームを実施した後に当落結果を確認します。
              </p>
              <p className="qa-page__text">
                月額定額プランに加えオプション料金が必要です。
              </p>
              <h2 className="qa-page__h2">③プレゼントで選べる選択肢について</h2>
              <p className="qa-page__text">
                プレゼントは「商品」「デジタルギフトコード」「デジタルクーポン」があります。
              </p>
              <h3 className="qa-page__h3">商品</h3>
              <p className="qa-page__text">
                配送が必要な商品をプレゼントできます。
              </p>
              <p className="qa-page__text">
                当選した参加者はPARKLoTが用意している専用ページから住所を登録します。
              </p>
              <p className="qa-page__text">
                開催者は登録された住所に商品を配送します。
              </p>
              <p className="qa-page__text">月額定額プラン内で実施できます。</p>
              <h3 className="qa-page__h3">デジタルギフトコード</h3>
              <p className="qa-page__text">
                Amazonギフト券などのデジタルギフトコードをプレゼントできます。
              </p>
              <p className="qa-page__text">
                当選者の一覧画面からTwitterのDMで手動でギフトコードを送信する場合は、月額定額プラン内で実施できます。
              </p>
              <p className="qa-page__text">
                当選者にギフトコードを自動送信する場合は、月額定額プランに加えオプション料金が必要です。
              </p>
              <h3 className="qa-page__h3">デジタルクーポン</h3>
              <p className="qa-page__text">
                実店舗やオンラインショプで使用できる独自クーポンをプレゼントできます。
              </p>
              <p className="qa-page__text">
                クーポンは「消込型クーポン」「時限式クーポン」と「期間中無制限クーポン」から選ぶことができます。
              </p>
              <p className="qa-page__text">
                「消込式クーポン」は店舗でスタッフが画面上にあるボタンをタップし、利用確認することで複数回の利用を防ぐことができます。
                <br />
                「時限式クーポン」は表示してから一定時間が経過すると自動で利用済みになり、クーポン利用時に店舗スタッフの手間がかかりません。
                <br />
                「期間中無限クーポン」は、指定期間中に何度でも利用できるので、継続的な利用を促進できます。
              </p>
              <p className="qa-page__text">月額定額プラン内で実施できます。</p>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default TypesOfCampaigns
